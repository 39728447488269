.services h1 {
    text-align: center;
    font-size: 50px;
    padding: 50px;
    font-weight: bold;
}

.services .img-cont {
    position: relative;
    height: 250px
}

.services .img-cont img {
    width: 100%;
    height: 100%;
}

.services .img-cont h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    padding: 50px;
    background-color: rgba(0, 0, 0, 0.315)
}