body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Cairo', sans-serif;
}

#root {
  overflow-x: hidden;
  font-family: 'Cairo', sans-serif;

}