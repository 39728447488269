.offers {
    background-image: url(./back2.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
}

.reserve .img-cont img {
    width: 100%;
}

.reserve h1 {
    text-align: center;
    font-size: 50px;
    padding: 50px;
    font-weight: bold;
}

.reserve h1 span {
    color: white;
    background-color: black;
    padding: 5px 10px;
}

.reserve .row div {
    text-align: center;
}

.reserve h3 {
    text-align: center;
    font-size: 30px
}

.reserve a {
    font-size: 18px;
}

.reserve h3 {
    text-align: center;
    font-size: 30px;
    padding-bottom: 10px;
}

.reserve button {
    border: none;
    background-color: transparent;
    font-size: 18px;
    transition: all 0.3s ease-in-out;
}

.reserve button:hover {
    font-size: 20px;
}

@media screen and (max-width: 768px) {
    .reserve h1 {
        font-size: 43px;
        line-height: 1.8
    }
}

@media screen and (max-width:426px) {
    .reserve h1 {
        font-size: 26px;
    }
}