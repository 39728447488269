.admin-home h1 {
    text-align: center;
    font-size: 50px;
    padding: 50px;
    font-weight: bold;
}

.single-class .number-badge {
    background-color: rgb(0, 195, 255);
    padding: 2px 5px;
    border-radius: 5px;
    color: white
}

.single-class {
    min-height: 400px
}

.single-class h1 {
    text-align: center;
}

.single-class .people {
    padding: 50px
}

.back {
    display: flex;
    justify-content: center;
    margin-bottom: 20px
}

.back a {
    font-weight: bold;
    font-size: 30px;
    text-decoration: none;
}