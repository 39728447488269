.hero {
    position: relative;
}

.hero .slider-img {
    width: 100%;
}

.hero .slider-img img {
    width: 100%;
    max-height: 700px;
}